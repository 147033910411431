@use 'partials/colors';

@mixin theme($theme) {
  app-json-validator {
    .mat-mdc-card {
      background-color: colors.bg($theme);
    }

    h2 {
      @include colors.text-secondary($theme);
    }

    .upload-container {
      .upload-container-button {
        @include colors.text-secondary($theme);
      }
    }

    .rosetta-class-select-menu {
      @include colors.text-secondary($theme);
    }

    .mat-toolbar-row span {
      @include colors.text-secondary($theme);
    }

    .results {
      .success::after {
        background: #28a745;
      }

      .fail::after {
        background: colors.warn($theme);
      }
    }
  }
}
