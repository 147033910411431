@use 'partials/colors';

@mixin theme($theme) {
  app-visualisation {
    .upload-container {
      .upload-container-button {
        @include colors.text-secondary($theme);
      }
    }

    .mat-expansion-panel-body {
      .sample-file-inner {
        &:hover {
          background-color: colors.bg-hover($theme);
        }
      }
    }

    .upload-error {
      color: colors.warn($theme);
    }

    .visualisation-errors {
      @include colors.text-secondary($theme);

      .content {
        .user-message {
          color: colors.warn($theme);
        }
      }
    }

    .visualisation-header {
      .display-name {
        @include colors.text-secondary($theme);
      }
    }

    .error-message {
      color: colors.warn($theme);
    }
  }
}
