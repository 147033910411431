@use 'partials/colors';

@mixin theme($theme) {
  app-rosetta-tree {
    .legend {
      fill: colors.bg($theme);
      stroke: colors.divider($theme);
      border-bottom: solid 1px;
    }

    .node circle {
      fill: #fff;
      stroke: steelblue;
    }
    .node text {
      fill: colors.text-secondary-color($theme);
    }

    .legend-text {
      fill: colors.text-secondary-color($theme);
    }

    .node-link {
      fill: none;
      stroke: colors.divider($theme);
      stroke-width: 1px;
    }

    .node-link-extends {
      stroke: steelblue;
      stroke-dasharray: 5;
      stroke-width: 1px;
    }

    .selected text {
      fill: #0f0;
    }

    .ghostCircle.over {
      fill: green !important;
      stroke: steelblue;
    }

    .templink {
      fill: none;
      stroke: red;
    }

    .node-text-header {
      stroke: black;
    }

    .node-text {
      stroke: darkblue;
    }

    .node-sub-text {
      stroke: #0c5460;
    }

    .arrow {
      fill: lightsteelblue;
    }
  }
}
