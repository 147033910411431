@use 'partials/colors';
@use 'mixins';

@mixin theme($theme) {
  .collapsible-panel-header {
    background-color: colors.bg-light-active($theme);
  }

  .collapsible-panel-body {
    background-color: colors.bg-lightest($theme);
  }

  .collapsible-panel {
    @include colors.borders($theme, top, bottom, left, right);

    &-closed,
    &-header.panel-header-can-close {
      &:hover {
        background-color: colors.bg-lightest-hover($theme);
        @include mixins.transitionFast(background-color);
      }
    }
  }
}
