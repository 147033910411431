@use 'partials/colors';

@mixin theme($theme) {
  app-workspace-recent-list {
    table .mat-mdc-row {
      .mat-column-version {
        color: colors.text-alt($theme);
      }

      &:hover {
        background: colors.bg-hover($theme);
      }

      &.disabled {
        cursor: default;
        background: colors.bg-hover($theme);
      }
    }
  }
}
