.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-row-center {
  @extend .flex-row;
  align-items: center;
}

.flex-row-center-center {
  @extend .flex-row-center;
  justify-content: center;
}

.flex-align-center {
  @extend .flex-column;
  align-items: center;
}

.flex-align-center-center {
  @extend .flex-align-center;
  justify-content: center;
}

.flex-space-between {
  @extend .flex;
  justify-content: space-between;
  align-items: flex-end;
}

.gap-1 {
  gap: 1rem;
}

.flex-end {
  @extend .flex;
  justify-content: flex-end;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.flex-right-align {
  margin-left: auto;
}

.align-self-center {
  align-self: center;
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.white-space-nowrap {
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

$list: (
  m: margin,
  p: padding,
  mr: margin-right,
  ml: margin-left,
  mt: margin-top,
  mb: margin-bottom,
  pb: padding-bottom,
  pt: padding-top,
  pr: padding-right,
  pl: padding-left,
);
$space-sizes: (0, 0) (1, 0.06) (4, 0.3) (5, 0.3) (8, 0.5) (16, 1) (24, 1.5)
  (32, 2) (48, 3);

@each $name, $item in $list {
  @each $i in $space-sizes {
    $sizePx: nth($i, 1);
    $sizeRem: nth($i, 2);

    .#{$name}-#{$sizePx} {
      #{$item}: #{$sizeRem}rem !important;
    }
  }
}
