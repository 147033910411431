@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primaryPalette: mat.get-color-from-palette($color-config, 'primary');

  .mat-mdc-slide-toggle {
    --mdc-theme-primary: #{map.get($primaryPalette, 500)};
    --mdc-switch-selected-icon-color: #{mat.get-contrast-color-from-palette(
        $primaryPalette,
        500
      )};

    --mdc-switch-selected-handle-color: #{map.get($primaryPalette, 500)};
    --mdc-switch-selected-hover-handle-color: #{map.get($primaryPalette, 500)};
    --mdc-switch-selected-focus-handle-color: #{map.get($primaryPalette, 500)};
    --mdc-switch-selected-pressed-handle-color: #{map.get($primaryPalette, 500)};

    --mdc-switch-selected-track-color: #{map.get($primaryPalette, 100)};
    --mdc-switch-selected-hover-track-color: #{map.get($primaryPalette, 100)};
    --mdc-switch-selected-focus-track-color: #{map.get($primaryPalette, 100)};
    --mdc-switch-selected-pressed-track-color: #{map.get($primaryPalette, 100)};
  }
}
