@use 'partials/colors';

@mixin theme($theme) {
  [app-banner] {
    display: block;
    background-color: colors.bg-light($theme);
    @include colors.borders($theme, bottom);

    .max-width-container {
      padding: 16px 24px;

      button {
        text-transform: uppercase;
        font-weight: bold;
        min-width: initial;
      }
    }
  }
}
