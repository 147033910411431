@use 'sass:map';
@use 'sass:color';
/*
  Monaco override styling

  This styling is required to fix UI bugs.
 */

.monaco-aria-container {
  // FF bug where an element is not reposition
  // due to no top or bottom position values set
  top: 0;
}

.light-theme .monaco-editor .goto-definition-link {
  color: $success_alt !important;
}

.dark-theme .monaco-editor .goto-definition-link {
  color: $light !important;
}

.light-theme .rosetta-light {
  .quick-input-widget .monaco-highlighted-label .highlight,
  .quick-input-widget
    .monaco-list-row.focused
    .monaco-highlighted-label
    .highlight {
    color: map.get($dark-pink-palette, 500);
  }

  &.monaco-editor {
    --vscode-editorInlayHint-background: #{color.adjust(
        map.get($highlight-blue-palette, 500),
        $alpha: -0.7
      )};
    --vscode-editorInlayHint-foreground: $dark;

    // --vscode-foreground: #616161;
    // --vscode-errorForeground: #a1260d;
    // --vscode-descriptionForeground: #717171;
    // --vscode-icon-foreground: #424242;
    // --vscode-focusBorder: #e94591;
    // --vscode-textSeparator-foreground: rgba(0, 0, 0, 0.18);
    // --vscode-textLink-foreground: #e94591;
    // --vscode-textLink-activeForeground: #e94591;
    // --vscode-textPreformat-foreground: #a31515;
    // --vscode-textBlockQuote-background: rgba(127, 127, 127, 0.1);
    // --vscode-textBlockQuote-border: #e94591;
    // --vscode-textCodeBlock-background: rgba(220, 220, 220, 0.4);
    // --vscode-widget-shadow: rgba(0, 0, 0, 0.16);
    // --vscode-input-background: #ffffff;
    // --vscode-input-foreground: #616161;
    // --vscode-inputOption-activeBorder: rgba(0, 122, 204, 0);
    // --vscode-inputOption-hoverBackground: rgba(184, 184, 184, 0.31);
    // --vscode-inputOption-activeBackground: rgba(233, 69, 145, 0.2);
    // --vscode-inputOption-activeForeground: #000000;
    // --vscode-input-placeholderForeground: #adadad;
    // --vscode-inputValidation-infoBackground: #e94591;
    // --vscode-inputValidation-infoBorder: #e94591;
    // --vscode-inputValidation-warningBackground: #f6f5d2;
    // --vscode-inputValidation-warningBorder: #b89500;
    // --vscode-inputValidation-errorBackground: #f2dede;
    // --vscode-inputValidation-errorBorder: #be1100;
    // --vscode-dropdown-background: #ffffff;
    // --vscode-dropdown-border: #cecece;
    // --vscode-checkbox-background: #ffffff;
    // --vscode-checkbox-border: #cecece;
    // --vscode-button-foreground: #ffffff;
    // --vscode-button-background: #e94591;
    // --vscode-button-hoverBackground: #d81a73;
    // --vscode-button-secondaryForeground: #ffffff;
    // --vscode-button-secondaryBackground: #5f6a79;
    // --vscode-button-secondaryHoverBackground: #4c5561;
    // --vscode-badge-background: #c4c4c4;
    // --vscode-badge-foreground: #333333;
    // --vscode-scrollbar-shadow: #dddddd;
    // --vscode-scrollbarSlider-background: rgba(100, 100, 100, 0.4);
    // --vscode-scrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
    // --vscode-scrollbarSlider-activeBackground: rgba(0, 0, 0, 0.6);
    // --vscode-progressBar-background: #e94591;
    // --vscode-editorError-foreground: #e51400;
    // --vscode-editorWarning-foreground: #bf8803;
    // --vscode-editorInfo-foreground: #e94591;
    // --vscode-editorInfo-border: #e94591;
    // --vscode-editorHint-foreground: #6c6c6c;
    // --vscode-sash-hoverBorder: #e94591;
    // --vscode-editor-background: #ffffff;
    // --vscode-editor-foreground: #0d0d0d;
    // --vscode-editorWidget-background: #f3f3f3;
    // --vscode-editorWidget-foreground: #616161;
    // --vscode-editorWidget-border: #c8c8c8;
    // --vscode-quickInput-background: #f3f3f3;
    // --vscode-quickInput-foreground: #616161;
    // --vscode-quickInputTitle-background: rgba(0, 0, 0, 0.06);
    // --vscode-pickerGroup-foreground: #e94591;
    // --vscode-pickerGroup-border: #cccedb;
    // --vscode-keybindingLabel-background: rgba(221, 221, 221, 0.4);
    // --vscode-keybindingLabel-foreground: #555555;
    // --vscode-keybindingLabel-border: rgba(204, 204, 204, 0.4);
    // --vscode-keybindingLabel-bottomBorder: rgba(187, 187, 187, 0.4);
    // --vscode-editor-selectionBackground: #add6ff;
    // --vscode-editor-inactiveSelectionBackground: #e5ebf1;
    // --vscode-editor-selectionHighlightBackground: #dceff2;
    // --vscode-editor-findMatchBackground: #a8ac94;
    // --vscode-editor-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
    // --vscode-editor-findRangeHighlightBackground: rgba(180, 180, 180, 0.3);
    // --vscode-searchEditor-findMatchBackground: rgba(234, 92, 0, 0.22);
    // --vscode-editor-hoverHighlightBackground: rgba(173, 214, 255, 0.15);
    // --vscode-editorHoverWidget-background: #f3f3f3;
    // --vscode-editorHoverWidget-foreground: #616161;
    // --vscode-editorHoverWidget-border: #c8c8c8;
    // --vscode-editorHoverWidget-statusBarBackground: #e7e7e7;
    // --vscode-editorLink-activeForeground: #0000ff;
    // --vscode-editorInlayHint-foreground: rgba(51, 51, 51, 0.8);
    // --vscode-editorInlayHint-background: rgba(196, 196, 196, 0.3);
    // --vscode-editorInlayHint-typeForeground: rgba(51, 51, 51, 0.8);
    // --vscode-editorInlayHint-typeBackground: rgba(196, 196, 196, 0.3);
    // --vscode-editorInlayHint-parameterForeground: rgba(51, 51, 51, 0.8);
    // --vscode-editorInlayHint-parameterBackground: rgba(196, 196, 196, 0.3);
    // --vscode-editorLightBulb-foreground: #ddb100;
    // --vscode-editorLightBulbAutoFix-foreground: #007acc;
    // --vscode-diffEditor-insertedTextBackground: rgba(155, 185, 85, 0.2);
    // --vscode-diffEditor-removedTextBackground: rgba(255, 0, 0, 0.2);
    // --vscode-diffEditor-diagonalFill: rgba(34, 34, 34, 0.2);
    // --vscode-list-focusOutline: #cc1598;
    // --vscode-list-activeSelectionBackground: #85dcef;
    // --vscode-list-activeSelectionForeground: #0d0d0d;
    // --vscode-list-inactiveSelectionBackground: #e4e6f1;
    // --vscode-list-inactiveFocusOutline: #cc1598;
    // --vscode-list-hoverBackground: #e8e8e8;
    // --vscode-list-dropBackground: #d6ebff;
    // --vscode-list-highlightForeground: #e94591;
    // --vscode-list-focusHighlightForeground: #9dddff;
    // --vscode-list-invalidItemForeground: #b89500;
    // --vscode-list-errorForeground: #b01011;
    // --vscode-list-warningForeground: #855f00;
    // --vscode-listFilterWidget-background: #efc1ad;
    // --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
    // --vscode-listFilterWidget-noMatchesOutline: #be1100;
    // --vscode-list-filterMatchBackground: rgba(234, 92, 0, 0.33);
    // --vscode-tree-indentGuidesStroke: #a9a9a9;
    // --vscode-tree-tableColumnsBorder: rgba(97, 97, 97, 0.13);
    // --vscode-tree-tableOddRowsBackground: rgba(97, 97, 97, 0.04);
    // --vscode-list-deemphasizedForeground: #8e8e90;
    // --vscode-quickInputList-focusForeground: #0d0d0d;
    // --vscode-quickInputList-focusBackground: #85dcef;
    // --vscode-menu-foreground: #616161;
    // --vscode-menu-background: #ffffff;
    // --vscode-menu-selectionForeground: #0d0d0d;
    // --vscode-menu-selectionBackground: #85dcef;
    // --vscode-menu-separatorBackground: #888888;
    // --vscode-toolbar-hoverBackground: rgba(184, 184, 184, 0.31);
    // --vscode-toolbar-activeBackground: rgba(166, 166, 166, 0.31);
    // --vscode-editor-snippetTabstopHighlightBackground: rgba(10, 50, 100, 0.2);
    // --vscode-editor-snippetFinalTabstopHighlightBorder: rgba(10, 50, 100, 0.5);
    // --vscode-breadcrumb-foreground: rgba(97, 97, 97, 0.8);
    // --vscode-breadcrumb-background: #ffffff;
    // --vscode-breadcrumb-focusForeground: #4e4e4e;
    // --vscode-breadcrumb-activeSelectionForeground: #4e4e4e;
    // --vscode-breadcrumbPicker-background: #f3f3f3;
    // --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
    // --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
    // --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
    // --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
    // --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
    // --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
    // --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
    // --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
    // --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
    // --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
    // --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
    // --vscode-minimap-findMatchHighlight: #d18616;
    // --vscode-minimap-selectionOccurrenceHighlight: #c9c9c9;
    // --vscode-minimap-selectionHighlight: #add6ff;
    // --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
    // --vscode-minimap-warningHighlight: #bf8803;
    // --vscode-minimap-foregroundOpacity: #000000;
    // --vscode-minimapSlider-background: rgba(100, 100, 100, 0.2);
    // --vscode-minimapSlider-hoverBackground: rgba(100, 100, 100, 0.35);
    // --vscode-minimapSlider-activeBackground: rgba(0, 0, 0, 0.3);
    // --vscode-problemsErrorIcon-foreground: #e51400;
    // --vscode-problemsWarningIcon-foreground: #bf8803;
    // --vscode-problemsInfoIcon-foreground: #e94591;
    // --vscode-charts-foreground: #616161;
    // --vscode-charts-lines: rgba(97, 97, 97, 0.5);
    // --vscode-charts-red: #e51400;
    // --vscode-charts-blue: #e94591;
    // --vscode-charts-yellow: #bf8803;
    // --vscode-charts-orange: #d18616;
    // --vscode-charts-green: #388a34;
    // --vscode-charts-purple: #652d90;
    // --vscode-editor-lineHighlightBackground: #fcfcfc;
    // --vscode-editor-lineHighlightBorder: #dbdbdb;
    // --vscode-editor-rangeHighlightBackground: rgba(253, 255, 0, 0.2);
    // --vscode-editor-symbolHighlightBackground: rgba(234, 92, 0, 0.33);
    // --vscode-editorCursor-foreground: #000000;
    // --vscode-editorWhitespace-foreground: rgba(51, 51, 51, 0.2);
    // --vscode-editorIndentGuide-background: #d3d3d3;
    // --vscode-editorIndentGuide-activeBackground: #939393;
    // --vscode-editorLineNumber-foreground: #237893;
    // --vscode-editorActiveLineNumber-foreground: #0b216f;
    // --vscode-editorLineNumber-activeForeground: #0b216f;
    // --vscode-editorRuler-foreground: #d3d3d3;
    // --vscode-editorCodeLens-foreground: #919191;
    // --vscode-editorBracketMatch-background: rgba(0, 100, 0, 0.1);
    // --vscode-editorBracketMatch-border: #b9b9b9;
    // --vscode-editorOverviewRuler-border: rgba(127, 127, 127, 0.3);
    // --vscode-editorGutter-background: #ffffff;
    // --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.47);
    // --vscode-editorGhostText-foreground: rgba(0, 0, 0, 0.47);
    // --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
    // --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
    // --vscode-editorOverviewRuler-warningForeground: #bf8803;
    // --vscode-editorOverviewRuler-infoForeground: #e94591;
    // --vscode-editorBracketHighlight-foreground1: #0431fa;
    // --vscode-editorBracketHighlight-foreground2: #319331;
    // --vscode-editorBracketHighlight-foreground3: #7b3814;
    // --vscode-editorBracketHighlight-foreground4: rgba(0, 0, 0, 0);
    // --vscode-editorBracketHighlight-foreground5: rgba(0, 0, 0, 0);
    // --vscode-editorBracketHighlight-foreground6: rgba(0, 0, 0, 0);
    // --vscode-editorBracketHighlight-unexpectedBracket-foreground: rgba(255, 18, 18, 0.8);
    // --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
    // --vscode-editorUnicodeHighlight-border: #cea33d;
    // --vscode-symbolIcon-arrayForeground: #616161;
    // --vscode-symbolIcon-booleanForeground: #616161;
    // --vscode-symbolIcon-classForeground: #d67e00;
    // --vscode-symbolIcon-colorForeground: #616161;
    // --vscode-symbolIcon-constantForeground: #616161;
    // --vscode-symbolIcon-constructorForeground: #652d90;
    // --vscode-symbolIcon-enumeratorForeground: #d67e00;
    // --vscode-symbolIcon-enumeratorMemberForeground: #007acc;
    // --vscode-symbolIcon-eventForeground: #d67e00;
    // --vscode-symbolIcon-fieldForeground: #007acc;
    // --vscode-symbolIcon-fileForeground: #616161;
    // --vscode-symbolIcon-folderForeground: #616161;
    // --vscode-symbolIcon-functionForeground: #652d90;
    // --vscode-symbolIcon-interfaceForeground: #007acc;
    // --vscode-symbolIcon-keyForeground: #616161;
    // --vscode-symbolIcon-keywordForeground: #616161;
    // --vscode-symbolIcon-methodForeground: #652d90;
    // --vscode-symbolIcon-moduleForeground: #616161;
    // --vscode-symbolIcon-namespaceForeground: #616161;
    // --vscode-symbolIcon-nullForeground: #616161;
    // --vscode-symbolIcon-numberForeground: #616161;
    // --vscode-symbolIcon-objectForeground: #616161;
    // --vscode-symbolIcon-operatorForeground: #616161;
    // --vscode-symbolIcon-packageForeground: #616161;
    // --vscode-symbolIcon-propertyForeground: #616161;
    // --vscode-symbolIcon-referenceForeground: #616161;
    // --vscode-symbolIcon-snippetForeground: #616161;
    // --vscode-symbolIcon-stringForeground: #616161;
    // --vscode-symbolIcon-structForeground: #616161;
    // --vscode-symbolIcon-textForeground: #616161;
    // --vscode-symbolIcon-typeParameterForeground: #616161;
    // --vscode-symbolIcon-unitForeground: #616161;
    // --vscode-symbolIcon-variableForeground: #007acc;
    // --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
    // --vscode-editor-linkedEditingBackground: rgba(255, 0, 0, 0.3);
    --vscode-editor-wordHighlightBackground: #{color.adjust(
        map.get($highlight-blue-palette, 500),
        $alpha: -0.75
      )};
    --vscode-editor-wordHighlightStrongBackground: #{color.adjust(
        map.get($highlight-blue-palette, 500),
        $alpha: -0.75
      )};
    // --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
    // --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
    // --vscode-peekViewTitle-background: rgba(233, 69, 145, 0.1);
    // --vscode-peekViewTitleLabel-foreground: #000000;
    // --vscode-peekViewTitleDescription-foreground: #616161;
    // --vscode-peekView-border: #e94591;
    // --vscode-peekViewResult-background: #f3f3f3;
    // --vscode-peekViewResult-lineForeground: #646465;
    // --vscode-peekViewResult-fileForeground: #1e1e1e;
    // --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
    // --vscode-peekViewResult-selectionForeground: #6c6c6c;
    // --vscode-peekViewEditor-background: #f2f8fc;
    // --vscode-peekViewEditorGutter-background: #f2f8fc;
    --vscode-peekViewResult-matchHighlightBackground: #{color.adjust(
        map.get($lime-palette, 700),
        $alpha: -0.7
      )};
    --vscode-peekViewEditor-matchHighlightBackground: #{color.adjust(
        map.get($dark-pink-palette, 100),
        $alpha: -0.75
      )};
    // --vscode-editorMarkerNavigationError-background: #e51400;
    // --vscode-editorMarkerNavigationError-headerBackground: rgba(229, 20, 0, 0.1);
    // --vscode-editorMarkerNavigationWarning-background: #bf8803;
    // --vscode-editorMarkerNavigationWarning-headerBackground: rgba(191, 136, 3, 0.1);
    // --vscode-editorMarkerNavigationInfo-background: #e94591;
    // --vscode-editorMarkerNavigationInfo-headerBackground: rgba(233, 69, 145, 0.1);
    // --vscode-editorMarkerNavigation-background: #ffffff;
    // --vscode-editorHoverWidget-highlightForeground: #e94591;
    // --vscode-editorSuggestWidget-background: #f3f3f3;
    // --vscode-editorSuggestWidget-border: #c8c8c8;
    // --vscode-editorSuggestWidget-foreground: #0d0d0d;
    // --vscode-editorSuggestWidget-selectedForeground: #0d0d0d;
    // --vscode-editorSuggestWidget-selectedBackground: #85dcef;
    // --vscode-editorSuggestWidget-highlightForeground: #e94591;
    // --vscode-editorSuggestWidget-focusHighlightForeground: #9dddff;
    // --vscode-editorSuggestWidgetStatus-foreground: rgba(13, 13, 13, 0.5);
    // --vscode-editor-foldBackground: rgba(173, 214, 255, 0.3);
    // --vscode-editorGutter-foldingControlForeground: #424242;
  }
}

.dark-theme .rosetta-dark {
  .quick-input-widget .monaco-highlighted-label .highlight {
    color: map.get($lime-palette, 500);
  }

  &.monaco-editor {
    --vscode-editorInlayHint-background: #{color.adjust(
        map.get($lime-palette, 500),
        $alpha: -0.7
      )};
    --vscode-editorInlayHint-foreground: $off-white;

    // --vscode-foreground: #cccccc;
    // --vscode-errorForeground: #f48771;
    // --vscode-descriptionForeground: rgba(204, 204, 204, 0.7);
    // --vscode-icon-foreground: #c5c5c5;
    // --vscode-focusBorder: #8c9621;
    // --vscode-textSeparator-foreground: rgba(255, 255, 255, 0.18);
    // --vscode-textLink-foreground: #8c9621;
    // --vscode-textLink-activeForeground: #8c9621;
    // --vscode-textPreformat-foreground: #d7ba7d;
    // --vscode-textBlockQuote-background: rgba(127, 127, 127, 0.1);
    // --vscode-textBlockQuote-border: #8c9621;
    // --vscode-textCodeBlock-background: rgba(10, 10, 10, 0.4);
    // --vscode-widget-shadow: rgba(0, 0, 0, 0.36);
    // --vscode-input-background: #3c3c3c;
    // --vscode-input-foreground: #cccccc;
    // --vscode-inputOption-activeBorder: rgba(0, 122, 204, 0);
    // --vscode-inputOption-hoverBackground: rgba(90, 93, 94, 0.5);
    // --vscode-inputOption-activeBackground: rgba(140, 150, 33, 0.4);
    // --vscode-inputOption-activeForeground: #ffffff;
    // --vscode-input-placeholderForeground: rgba(204, 204, 204, 0.5);
    // --vscode-inputValidation-infoBackground: #8c9621;
    // --vscode-inputValidation-infoBorder: #8c9621;
    // --vscode-inputValidation-warningBackground: #352a05;
    // --vscode-inputValidation-warningBorder: #b89500;
    // --vscode-inputValidation-errorBackground: #5a1d1d;
    // --vscode-inputValidation-errorBorder: #be1100;
    // --vscode-dropdown-background: #3c3c3c;
    // --vscode-dropdown-foreground: #f0f0f0;
    // --vscode-dropdown-border: #3c3c3c;
    // --vscode-checkbox-background: #3c3c3c;
    // --vscode-checkbox-foreground: #f0f0f0;
    // --vscode-checkbox-border: #3c3c3c;
    // --vscode-button-foreground: #ffffff;
    // --vscode-button-background: #8c9621;
    // --vscode-button-hoverBackground: #a8b428;
    // --vscode-button-secondaryForeground: #ffffff;
    // --vscode-button-secondaryBackground: #3a3d41;
    // --vscode-button-secondaryHoverBackground: #45494e;
    // --vscode-badge-background: #4d4d4d;
    // --vscode-badge-foreground: #ffffff;
    // --vscode-scrollbar-shadow: #000000;
    // --vscode-scrollbarSlider-background: rgba(121, 121, 121, 0.4);
    // --vscode-scrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
    // --vscode-scrollbarSlider-activeBackground: rgba(191, 191, 191, 0.4);
    // --vscode-progressBar-background: #8c9621;
    // --vscode-editorError-foreground: #f14c4c;
    // --vscode-editorWarning-foreground: #cca700;
    // --vscode-editorInfo-foreground: #8c9621;
    // --vscode-editorInfo-border: #8c9621;
    // --vscode-editorHint-foreground: rgba(238, 238, 238, 0.7);
    // --vscode-sash-hoverBorder: #8c9621;
    // --vscode-editor-background: #2b2b2b;
    // --vscode-editor-foreground: #d4d4d4;
    // --vscode-editorWidget-background: #252526;
    // --vscode-editorWidget-foreground: #cccccc;
    // --vscode-editorWidget-border: #454545;
    // --vscode-quickInput-background: #252526;
    // --vscode-quickInput-foreground: #cccccc;
    // --vscode-quickInputTitle-background: rgba(255, 255, 255, 0.1);
    // --vscode-pickerGroup-foreground: #8c9621;
    // --vscode-pickerGroup-border: #3f3f46;
    // --vscode-keybindingLabel-background: rgba(128, 128, 128, 0.17);
    // --vscode-keybindingLabel-foreground: #cccccc;
    // --vscode-keybindingLabel-border: rgba(51, 51, 51, 0.6);
    // --vscode-keybindingLabel-bottomBorder: rgba(68, 68, 68, 0.6);
    // --vscode-editor-selectionBackground: #264f78;
    // --vscode-editor-inactiveSelectionBackground: #3a3d41;
    // --vscode-editor-selectionHighlightBackground: rgba(173, 214, 255, 0.15);
    // --vscode-editor-findMatchBackground: #515c6a;
    // --vscode-editor-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
    // --vscode-editor-findRangeHighlightBackground: rgba(58, 61, 65, 0.4);
    // --vscode-searchEditor-findMatchBackground: rgba(234, 92, 0, 0.22);
    // --vscode-editor-hoverHighlightBackground: rgba(38, 79, 120, 0.25);
    // --vscode-editorHoverWidget-background: #252526;
    // --vscode-editorHoverWidget-foreground: #cccccc;
    // --vscode-editorHoverWidget-border: #454545;
    // --vscode-editorHoverWidget-statusBarBackground: #2c2c2d;
    // --vscode-editorLink-activeForeground: #4e94ce;
    // --vscode-editorInlayHint-foreground: rgba(255, 255, 255, 0.8);
    // --vscode-editorInlayHint-background: rgba(77, 77, 77, 0.6);
    // --vscode-editorInlayHint-typeForeground: rgba(255, 255, 255, 0.8);
    // --vscode-editorInlayHint-typeBackground: rgba(77, 77, 77, 0.6);
    // --vscode-editorInlayHint-parameterForeground: rgba(255, 255, 255, 0.8);
    // --vscode-editorInlayHint-parameterBackground: rgba(77, 77, 77, 0.6);
    // --vscode-editorLightBulb-foreground: #ffcc00;
    // --vscode-editorLightBulbAutoFix-foreground: #75beff;
    // --vscode-diffEditor-insertedTextBackground: rgba(155, 185, 85, 0.2);
    // --vscode-diffEditor-removedTextBackground: rgba(255, 0, 0, 0.2);
    // --vscode-diffEditor-diagonalFill: rgba(204, 204, 204, 0.2);
    // --vscode-list-focusOutline: #c9d646;
    // --vscode-list-activeSelectionBackground: #585b38;
    // --vscode-list-activeSelectionForeground: #d1d1d1;
    // --vscode-list-inactiveSelectionBackground: #37373d;
    // --vscode-list-inactiveFocusOutline: #c9d646;
    // --vscode-list-hoverBackground: #2a2d2e;
    // --vscode-list-dropBackground: #062f4a;
    // --vscode-list-highlightForeground: #8c9621;
    // --vscode-list-focusHighlightForeground: #8c9621;
    // --vscode-list-invalidItemForeground: #b89500;
    // --vscode-list-errorForeground: #f88070;
    // --vscode-list-warningForeground: #cca700;
    // --vscode-listFilterWidget-background: #653723;
    // --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
    // --vscode-listFilterWidget-noMatchesOutline: #be1100;
    // --vscode-list-filterMatchBackground: rgba(234, 92, 0, 0.33);
    // --vscode-tree-indentGuidesStroke: #585858;
    // --vscode-tree-tableColumnsBorder: rgba(204, 204, 204, 0.13);
    // --vscode-tree-tableOddRowsBackground: rgba(204, 204, 204, 0.04);
    // --vscode-list-deemphasizedForeground: #8c8c8c;
    // --vscode-quickInputList-focusForeground: #d1d1d1;
    // --vscode-quickInputList-focusBackground: #585b38;
    // --vscode-menu-foreground: #f0f0f0;
    // --vscode-menu-background: #3c3c3c;
    // --vscode-menu-selectionForeground: #d1d1d1;
    // --vscode-menu-selectionBackground: #585b38;
    // --vscode-menu-separatorBackground: #bbbbbb;
    // --vscode-toolbar-hoverBackground: rgba(90, 93, 94, 0.31);
    // --vscode-toolbar-activeBackground: rgba(99, 102, 103, 0.31);
    // --vscode-editor-snippetTabstopHighlightBackground: rgba(124, 124, 124, 0.3);
    // --vscode-editor-snippetFinalTabstopHighlightBorder: #525252;
    // --vscode-breadcrumb-foreground: rgba(204, 204, 204, 0.8);
    // --vscode-breadcrumb-background: #2b2b2b;
    // --vscode-breadcrumb-focusForeground: #e0e0e0;
    // --vscode-breadcrumb-activeSelectionForeground: #e0e0e0;
    // --vscode-breadcrumbPicker-background: #252526;
    // --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
    // --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
    // --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
    // --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
    // --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
    // --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
    // --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
    // --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
    // --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
    // --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
    // --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
    // --vscode-minimap-findMatchHighlight: #d18616;
    // --vscode-minimap-selectionOccurrenceHighlight: #676767;
    // --vscode-minimap-selectionHighlight: #264f78;
    // --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
    // --vscode-minimap-warningHighlight: #cca700;
    // --vscode-minimap-foregroundOpacity: #000000;
    // --vscode-minimapSlider-background: rgba(121, 121, 121, 0.2);
    // --vscode-minimapSlider-hoverBackground: rgba(100, 100, 100, 0.35);
    // --vscode-minimapSlider-activeBackground: rgba(191, 191, 191, 0.2);
    // --vscode-problemsErrorIcon-foreground: #f14c4c;
    // --vscode-problemsWarningIcon-foreground: #cca700;
    // --vscode-problemsInfoIcon-foreground: #8c9621;
    // --vscode-charts-foreground: #cccccc;
    // --vscode-charts-lines: rgba(204, 204, 204, 0.5);
    // --vscode-charts-red: #f14c4c;
    // --vscode-charts-blue: #8c9621;
    // --vscode-charts-yellow: #cca700;
    // --vscode-charts-orange: #d18616;
    // --vscode-charts-green: #89d185;
    // --vscode-charts-purple: #b180d7;
    // --vscode-editor-lineHighlightBackground: #323232;
    // --vscode-editor-lineHighlightBorder: #777777;
    // --vscode-editor-rangeHighlightBackground: rgba(255, 255, 255, 0.04);
    // --vscode-editor-symbolHighlightBackground: rgba(234, 92, 0, 0.33);
    // --vscode-editorCursor-foreground: #aeafad;
    // --vscode-editorWhitespace-foreground: rgba(227, 228, 226, 0.16);
    // --vscode-editorIndentGuide-background: #404040;
    // --vscode-editorIndentGuide-activeBackground: #707070;
    // --vscode-editorLineNumber-foreground: #858585;
    // --vscode-editorActiveLineNumber-foreground: #c6c6c6;
    // --vscode-editorLineNumber-activeForeground: #c6c6c6;
    // --vscode-editorRuler-foreground: #5a5a5a;
    // --vscode-editorCodeLens-foreground: #999999;
    // --vscode-editorBracketMatch-background: rgba(0, 100, 0, 0.1);
    // --vscode-editorBracketMatch-border: #888888;
    // --vscode-editorOverviewRuler-border: rgba(127, 127, 127, 0.3);
    // --vscode-editorGutter-background: #2b2b2b;
    // --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.67);
    // --vscode-editorGhostText-foreground: rgba(255, 255, 255, 0.34);
    // --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
    // --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
    // --vscode-editorOverviewRuler-warningForeground: #cca700;
    // --vscode-editorOverviewRuler-infoForeground: #8c9621;
    // --vscode-editorBracketHighlight-foreground1: #ffd700;
    // --vscode-editorBracketHighlight-foreground2: #da70d6;
    // --vscode-editorBracketHighlight-foreground3: #179fff;
    // --vscode-editorBracketHighlight-foreground4: rgba(0, 0, 0, 0);
    // --vscode-editorBracketHighlight-foreground5: rgba(0, 0, 0, 0);
    // --vscode-editorBracketHighlight-foreground6: rgba(0, 0, 0, 0);
    // --vscode-editorBracketHighlight-unexpectedBracket-foreground: rgba(255, 18, 18, 0.8);
    // --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
    // --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
    // --vscode-editorUnicodeHighlight-border: #bd9b03;
    // --vscode-symbolIcon-arrayForeground: #cccccc;
    // --vscode-symbolIcon-booleanForeground: #cccccc;
    // --vscode-symbolIcon-classForeground: #ee9d28;
    // --vscode-symbolIcon-colorForeground: #cccccc;
    // --vscode-symbolIcon-constantForeground: #cccccc;
    // --vscode-symbolIcon-constructorForeground: #b180d7;
    // --vscode-symbolIcon-enumeratorForeground: #ee9d28;
    // --vscode-symbolIcon-enumeratorMemberForeground: #75beff;
    // --vscode-symbolIcon-eventForeground: #ee9d28;
    // --vscode-symbolIcon-fieldForeground: #75beff;
    // --vscode-symbolIcon-fileForeground: #cccccc;
    // --vscode-symbolIcon-folderForeground: #cccccc;
    // --vscode-symbolIcon-functionForeground: #b180d7;
    // --vscode-symbolIcon-interfaceForeground: #75beff;
    // --vscode-symbolIcon-keyForeground: #cccccc;
    // --vscode-symbolIcon-keywordForeground: #cccccc;
    // --vscode-symbolIcon-methodForeground: #b180d7;
    // --vscode-symbolIcon-moduleForeground: #cccccc;
    // --vscode-symbolIcon-namespaceForeground: #cccccc;
    // --vscode-symbolIcon-nullForeground: #cccccc;
    // --vscode-symbolIcon-numberForeground: #cccccc;
    // --vscode-symbolIcon-objectForeground: #cccccc;
    // --vscode-symbolIcon-operatorForeground: #cccccc;
    // --vscode-symbolIcon-packageForeground: #cccccc;
    // --vscode-symbolIcon-propertyForeground: #cccccc;
    // --vscode-symbolIcon-referenceForeground: #cccccc;
    // --vscode-symbolIcon-snippetForeground: #cccccc;
    // --vscode-symbolIcon-stringForeground: #cccccc;
    // --vscode-symbolIcon-structForeground: #cccccc;
    // --vscode-symbolIcon-textForeground: #cccccc;
    // --vscode-symbolIcon-typeParameterForeground: #cccccc;
    // --vscode-symbolIcon-unitForeground: #cccccc;
    // --vscode-symbolIcon-variableForeground: #75beff;
    // --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
    // --vscode-editor-linkedEditingBackground: rgba(255, 0, 0, 0.3);
    // --vscode-editor-wordHighlightBackground: rgba(87, 87, 87, 0.72);
    // --vscode-editor-wordHighlightStrongBackground: rgba(0, 73, 114, 0.72);
    // --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
    // --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
    // --vscode-peekViewTitle-background: rgba(140, 150, 33, 0.1);
    // --vscode-peekViewTitleLabel-foreground: #ffffff;
    // --vscode-peekViewTitleDescription-foreground: rgba(204, 204, 204, 0.7);
    // --vscode-peekView-border: #8c9621;
    // --vscode-peekViewResult-background: #252526;
    // --vscode-peekViewResult-lineForeground: #bbbbbb;
    // --vscode-peekViewResult-fileForeground: #ffffff;
    // --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
    // --vscode-peekViewResult-selectionForeground: #ffffff;
    // --vscode-peekViewEditor-background: #001f33;
    // --vscode-peekViewEditorGutter-background: #001f33;
    --vscode-peekViewResult-matchHighlightBackground: #{color.adjust(
        map.get($lime-palette, 500),
        $alpha: -0.6
      )};
    --vscode-peekViewEditor-matchHighlightBackground: #{color.adjust(
        map.get($lime-palette, 500),
        $alpha: -0.6
      )};
    // --vscode-editorMarkerNavigationError-background: #f14c4c;
    // --vscode-editorMarkerNavigationError-headerBackground: rgba(241, 76, 76, 0.1);
    // --vscode-editorMarkerNavigationWarning-background: #cca700;
    // --vscode-editorMarkerNavigationWarning-headerBackground: rgba(204, 167, 0, 0.1);
    // --vscode-editorMarkerNavigationInfo-background: #8c9621;
    // --vscode-editorMarkerNavigationInfo-headerBackground: rgba(140, 150, 33, 0.1);
    // --vscode-editorMarkerNavigation-background: #2b2b2b;
    // --vscode-editorHoverWidget-highlightForeground: #8c9621;
    // --vscode-editorSuggestWidget-background: #252526;
    // --vscode-editorSuggestWidget-border: #454545;
    // --vscode-editorSuggestWidget-foreground: #d4d4d4;
    // --vscode-editorSuggestWidget-selectedForeground: #d1d1d1;
    // --vscode-editorSuggestWidget-selectedBackground: #585b38;
    // --vscode-editorSuggestWidget-highlightForeground: #8c9621;
    // --vscode-editorSuggestWidget-focusHighlightForeground: #8c9621;
    // --vscode-editorSuggestWidgetStatus-foreground: rgba(212, 212, 212, 0.5);
    // --vscode-editor-foldBackground: rgba(38, 79, 120, 0.3);
    // --vscode-editorGutter-foldingControlForeground: #c5c5c5;
  }
}

.rosetta-dark,
.rosetta-light {
  &.monaco-editor {
    [class*='dyn-rule-'] {
      vertical-align: baseline;
    }

    .wordHighlightStrong {
      background-color: var(--vscode-editor-wordHighlightStrongBackground);
    }
  }
}
