@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use 'partials/colors';
@use 'partials/palette';
@use 'mixins';

/*
Define all themed Rosetta variables to be used throughout the app.

The variable name should follow this naming convention:
  {system}-{ui element}-{category}-{variant}-{property}-{state}
  e.g. --rosetta-button-color-primary-bg-hover

If variant or state is not specified, then assume it is the default.
*/

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark-theme: map.get($color-config, 'is-dark');

  // Brand colors
  --rosetta-brand-color-primary: #{colors.primary($theme)};
  --rosetta-brand-color-accent: #{colors.accent($theme)};

  // Text colors
  --rosetta-text-color: #{colors.text-color($theme)};
  --rosetta-text-color-success: #{palette.$success};
  --rosetta-text-color-pending: #{palette.$pending};
  --rosetta-text-color-alt: #{colors.text-alt($theme)};
  --rosetta-text-color-warn: #{colors.warn($theme)};
  --rosetta-text-color-warn-contrast: #{colors.warn-contrast($theme)};
  --rosetta-text-color-primary: #{colors.if(
      $is-dark-theme,
      mat.get-color-from-palette(palette.$lime-palette, 500),
      mat.get-color-from-palette(palette.$dark-pink-palette, 500)
    )};
  --rosetta-text-color-primary-hover: #{colors.if(
      $is-dark-theme,
      mat.get-color-from-palette(palette.$lime-palette, 700),
      mat.get-color-from-palette(palette.$dark-pink-palette, 700)
    )};
  --rosetta-text-color-primary-contrast: #{colors.if(
      $is-dark-theme,
      mat.get-contrast-color-from-palette(palette.$lime-palette, 500),
      mat.get-contrast-color-from-palette(palette.$light-pink-palette, 500)
    )};
  --rosetta-text-color-primary-contrast-50: #{colors.if(
      $is-dark-theme,
      color.adjust(
        mat.get-color-from-palette(palette.$lime-palette, 500),
        $alpha: -0.6
      ),
      color.adjust(
        mat.get-color-from-palette(palette.$light-pink-palette, 500),
        $alpha: -0.6
      )
    )};
  --rosetta-text-color-primary-alt: #{colors.if(
      $is-dark-theme,
      mat.get-color-from-palette(palette.$lime-palette, 500),
      mat.get-color-from-palette(palette.$light-pink-palette, 700)
    )};
  --rosetta-text-color-accent: #{colors.if(
      $is-dark-theme,
      mat.get-color-from-palette(palette.$light-pink-palette, 500),
      mat.get-color-from-palette(palette.$lime-palette, 500)
    )};
  --rosetta-text-color-highlight: #{mat.get-color-from-palette(
      palette.$highlight-blue-palette,
      500
    )};

  // Background colors
  --rosetta-color-bg: #{colors.bg($theme)};
  --rosetta-color-bg-primary: #{colors.if(
      $is-dark-theme,
      mat.get-color-from-palette(palette.$lime-palette, 500),
      mat.get-color-from-palette(palette.$light-pink-palette, 500)
    )};
  --rosetta-color-bg-primary-50: #{colors.if(
      $is-dark-theme,
      color.adjust(
        mat.get-color-from-palette(palette.$lime-palette, 500),
        $alpha: -0.6
      ),
      color.adjust(
        mat.get-color-from-palette(palette.$light-pink-palette, 500),
        $alpha: -0.6
      )
    )};

  --rosetta-color-bg-alt: #{colors.bg-alt($theme)};
  // Todo: Review alt colours and give them more meaningful names
  --rosetta-color-bg-alt-2: #{colors.bg-status-bar($theme)};
  --rosetta-color-bg-alt-3: #{colors.bg-app-bar($theme)};
  --rosetta-color-bg-dark: #{colors.darkLight($theme, #2b2b2b, #ffffff)};
  --rosetta-color-bg-dark-2: #{darken(colors.bg($theme), 12)};
  --rosetta-color-bg-warn: #{colors.warn($theme)};
  --rosetta-color-bg-success: #{palette.$success_alt};
  --rosetta-color-bg-light: #{colors.bg-light($theme)};
  --rosetta-color-bg-light-active: #{colors.bg-light-active($theme)};
  --rosetta-color-bg-light-hover: #{colors.bg-light($theme)};

  // Border colors
  --rosetta-divider-color: #{colors.divider($theme)};
  --rosetta-border-color: #{if(
      $is-dark-theme,
      rgba(#232323, 0.7),
      rgba(#c9c7c7, 0.5)
    )};
  --rosetta-border-color-dark: #{if(
      $is-dark-theme,
      rgba(white, 0.5),
      rgba(black, 0.5)
    )};
  --rosetta-border-color-light: rgba(0, 0, 0, 0.12);
  --rosetta-border-color-light-active: #{colors.bg-light-active($theme)};
}
