%flex-row-component {
  display: flex;
  flex-direction: row;
}

%flex-column-component {
  display: flex;
  flex-direction: column;
}

%flex-row-component-vert-center {
  display: flex;
  align-items: center;
}

%text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

%fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

%center-loading-spinner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
