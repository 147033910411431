@use 'partials/colors';
@use 'partials/variables' as var;

@mixin theme($theme) {
  .no-padding-dialog-container {
    .mat-mdc-dialog-container {
      padding: 0;
    }

    .mat-mdc-dialog-surface {
      border-radius: 0;
    }
  }

  .xs-dialog,
  .sm-dialog,
  .md-dialog,
  .lg-dialog,
  .max-dialog {
    .mat-mdc-dialog-title {
      color: colors.text-color($theme);
      background-color: colors.bg-status-bar($theme);
      padding: 0 0.5rem 0 1.5rem;
      height: 40px;
      width: auto;
      display: flex;

      h2 {
        font-size: 18px;
        color: inherit;
        align-self: center;
        margin: 0;
      }
    }

    .mat-mdc-dialog-container {
      border-radius: var.$border-radius;
    }

    .mat-mdc-dialog-actions {
      padding: 0.5rem 1.5rem 1.5rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}
