@use 'partials/variables' as var;

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-italic {
  font-style: italic;
}

.text-wrap {
  white-space: normal;
}

.text-rtl {
  direction: rtl;
}

.text-align {
  &-center {
    text-align: center;
  }

  &-left {
    text-align: left !important;
  }

  &-right {
    text-align: right !important;
  }
}

.text-clamp-3 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.font-code {
  font-family: var.$code-font-family;
  font-size: var.$font-size-xs;
  font-weight: normal;
  line-height: 1.125rem;
  letter-spacing: 0px;
}

h5 {
  &.sub-heading {
    text-transform: uppercase;
    font-weight: bold;
    font-size: var.$font-size-xxs;
    letter-spacing: 1px;
    margin-bottom: 0.25rem;
  }
}
