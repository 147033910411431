@use 'partials/colors';
@use 'mixins';

@mixin theme($theme) {
  as-split {
    > .as-split-gutter {
      @include mixins.transitionFast(border-color, true);
    }

    &.as-init .as-split-gutter {
      background-color: colors.bg-light-active($theme);

      &:hover {
        border-color: colors.primary($theme);
      }
    }

    &.as-horizontal > .as-split-gutter {
      @include colors.borders($theme, right);
    }

    &.as-vertical > .as-split-gutter {
      @include colors.borders($theme, top);
    }
  }
}
