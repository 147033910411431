@use 'partials/colors';

@mixin theme($theme) {
  .workspaces-section {
    // These styles here because there is a bug with dynamically inserted component style scoping
    .loading-spinner {
      height: 15vh;
    }
  }
}
