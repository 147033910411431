@use 'sass:color';
@use 'partials/colors';

@mixin theme($theme) {
  mat-optgroup {
    --mat-optgroup-label-text-color: #{color.adjust(
        colors.text-color($theme),
        $alpha: -0.6
      )};

    &.sticky-groups .mat-mdc-optgroup-label {
      background-color: var(--mat-select-panel-background-color);
      position: sticky;
      top: -0.5rem;
      z-index: 1;
    }
  }
}
