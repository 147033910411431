.touranchor--is-active {
  position: relative;

  &:after {
    content: '';
    display: block;
    inset: -4px;
    position: absolute;
    opacity: 0.5;
    background: var(--rosetta-brand-color-primary);
    box-shadow: 0 0 6px 1px var(--rosetta-brand-color-primary);
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step.arrow.mat-menu-below:not(
    .horizontal
  ) {
  &:after {
    border-bottom-color: var(--rosetta-color-bg-dark) !important;
  }

  &:before {
    border-bottom-color: var(--rosetta-color-bg-dark) !important;
  }
}
