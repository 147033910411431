@use 'sass:map';
@use '@angular/material' as mat;
@use 'partials/colors';

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primaryPalette: mat.get-color-from-palette($color-config, 'primary');

  .feature-row {
    background-color: colors.bg-light($theme);

    &.border-top {
      @include colors.borders($theme, top);
    }

    &.border-bottom {
      @include colors.borders($theme, bottom);
    }

    .mat-mdc-tab-links {
      align-items: flex-end; // Ensure tabs are aligned to the bottom
      justify-content: space-between;
    }

    .mat-mdc-tab-link {
      opacity: 0.6;

      &:not(:disabled) {
        &:hover {
          cursor: pointer;
          opacity: 1;
          background-color: rgba(map.get($primaryPalette, 500), 0.05);
          transition:
            background-color 0.3s ease-in-out,
            opacity 0.3s ease-in-out;

          a {
            color: colors.primary($theme);
          }
        }

        &.mdc-tab--active {
          opacity: 1;
          background-color: colors.bg-light-active($theme);

          fa-icon {
            color: map.get($primaryPalette, 500);
          }
        }
      }
    }

    .tab-group {
      @include colors.borders($theme, left);
      @include colors.borders($theme, right);

      .mat-mdc-tab-link {
        &:not(:last-child) {
          .tab-content {
            @include colors.borders($theme, right);
          }
        }
      }
    }
  }
}
