.overflow-hidden {
  overflow: hidden;
}

.ignore-element-styles {
  // These elements don't produce a specific box by themselves and all styles are ignored
  display: contents;
}

.opacity-50 {
  opacity: 0.5;
}

.list-style-disc {
  list-style-type: disc;
}

.word-wrap {
  white-space: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-force {
  cursor: pointer;
  pointer-events: all;
}
