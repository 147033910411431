@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;
@use 'partials/palette';

@function if($condition, $if-value, $else-value) {
  @if ($condition ==true) {
    @return $if-value;
  } @else {
    @return $else-value;
  }
}

@function isDarkTheme($theme) {
  $color-config: mat.get-color-config($theme);
  @return map.get($color-config, 'is-dark');
}

@function darkLight($theme, $darkColor, $lightColor) {
  $isDark: isDarkTheme($theme);
  @return if($isDark, $darkColor, $lightColor);
}

@function getPalette($theme, $name) {
  $color-config: mat.get-color-config($theme);
  @return mat.get-color-from-palette($color-config, $name);
}

@function text-opacity($theme, $opacity: 1) {
  @return color.adjust(
    map.get(getPalette($theme, 'foreground'), 'text'),
    $alpha: $opacity
  );
}

@mixin text($theme) {
  color: map.get(getPalette($theme, 'foreground'), 'text');
}

@function text-color($theme) {
  @return map.get(getPalette($theme, 'foreground'), 'text');
}

@function text-alt($theme) {
  @return map.get(getPalette($theme, 'foreground'), 'hint-text');
}

@mixin text-secondary($theme) {
  color: map.get(getPalette($theme, 'foreground'), 'secondary-text');
}

@function text-secondary-color($theme) {
  @return map.get(getPalette($theme, 'foreground'), 'secondary-text');
}

@function text-disabled($theme) {
  @return map.get(getPalette($theme, 'foreground'), 'disabled-text');
}

@function divider($theme) {
  @return map.get(getPalette($theme, 'foreground'), 'divider');
}

@mixin borders($theme, $sides...) {
  @each $side in $sides {
    border-#{$side}: 1px
      solid
      darkLight($theme, rgba(#232323, 0.7), rgba(#c9c7c7, 0.5));
  }
}

@function border-color($theme) {
  @return darkLight($theme, rgba(#232323, 0.7), rgba(#c9c7c7, 0.5));
}

@function border-color-light($theme) {
  @return darkLight($theme, rgba(#ffffff, 0.12), rgba(#000000, 0.12));
}

@function bg($theme) {
  @return map.get(getPalette($theme, 'background'), 'background');
}

@function bg-alt($theme) {
  @return map.get(getPalette($theme, 'background'), 'card');
}

@function bg-app-bar($theme) {
  @return darkLight($theme, #2f2f2f, #f5f5f5);
}

@function bg-status-bar($theme) {
  @return map.get(getPalette($theme, 'background'), 'status-bar');
}

@function bg-selected($theme) {
  @return map.get(getPalette($theme, 'background'), 'selected-button');
}

@function bg-focused($theme) {
  @return map.get(getPalette($theme, 'background'), 'focused-button');
}

@function bg-hover($theme) {
  @return map.get(getPalette($theme, 'background'), 'hover');
}

@function bg-lightest($theme) {
  @return darkLight($theme, #1f1f1f, #f6f6f6);
}

@function bg-lightest-hover($theme) {
  $color-config: mat.get-color-config($theme);
  $foregroundPalette: mat.get-color-from-palette($color-config, 'foreground');
  $backgroundPalette: mat.get-color-from-palette($color-config, 'background');

  @return color.mix(
    map.get($backgroundPalette, 'background'),
    map.get($foregroundPalette, 'base'),
    90%
  );
}

@function bg-light($theme) {
  @return darkLight($theme, #393939, #f8f8f8);
}

@function bg-light-active($theme) {
  @return darkLight($theme, #2f2f2f, #eeeeee);
}

@mixin bg-dark($theme) {
  @if (isDarkTheme($theme)) {
    background-color: #2b2b2b;
    color: palette.$off-white;
  } @else {
    color: #2b2b2b;
    background-color: #adadad;
  }
}

@mixin bg-darkest($theme) {
  background-color: darkLight($theme, #2b2b2b, #434343);
  color: palette.$off-white;
}

@function primary($theme, $shade: 500) {
  $color-config: mat.get-color-config($theme);
  $primaryPalette: mat.get-color-from-palette($color-config, 'primary');
  @return map.get($primaryPalette, $shade);
}

@function primary-contrast($theme, $shade: 500) {
  $color-config: mat.get-color-config($theme);
  $primaryPalette: mat.get-color-from-palette($color-config, 'primary');
  @return mat.get-contrast-color-from-palette($primaryPalette, $shade);
}

@function accent($theme, $shade: 500) {
  $color-config: mat.get-color-config($theme);
  $accentPalette: mat.get-color-from-palette($color-config, 'accent');
  @return map.get($accentPalette, $shade);
}

@function warn($theme, $shade: 500) {
  $color-config: mat.get-color-config($theme);
  $palette: mat.get-color-from-palette($color-config, 'warn');
  @return map.get($palette, $shade);
}

@function warn-contrast($theme, $shade: 500) {
  $color-config: mat.get-color-config($theme);
  $palette: mat.get-color-from-palette($color-config, 'warn');
  @return mat.get-contrast-color-from-palette($palette, $shade);
}
