@use 'partials/colors';

@mixin theme($theme) {
  app-code-view-selected {
    .node-container {
      background-color: colors.bg-light-active($theme);
      color: colors.text-color($theme);
      @include colors.borders($theme, 'top');
    }
  }
}
