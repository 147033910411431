@use 'partials/colors';

@mixin theme($theme) {
  .app-recent-menu {
    background: colors.bg-alt($theme);

    h4 {
      color: colors.text-color($theme);
    }

    .mat-mdc-row {
      &:hover {
        background: colors.bg-hover($theme);
      }
    }

    .recent-list-actions {
      border-top-color: colors.text-alt($theme);
    }
  }
}
