@import './partials';

// These should only be imported once
@import './helpers';
@import './intercom-overrides';
@import './monaco-overrides';
@import './as-split-overrides';
@import './ng-scrollbar-overrides';
@import './tour-overrides';
@import './theme';

@import './typography/font-styles';

@import './generic';

html,
body {
  height: 100%;
  margin: 0;
  min-width: 1024px;
}

.mat-ripple-element {
  z-index: -1;
}

.content-wrapper-text table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.content-wrapper-text th {
  border: 1px solid;
  padding: 0.25rem;
}

.content-wrapper-text td {
  border: 1px solid;
  padding: 0.25rem;
  vertical-align: top;
  text-align: center;
}
