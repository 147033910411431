@import 'partials/colors';

@mixin theme($theme) {
  .provision-item {
    .symbol-link {
      color: primary($theme);

      &:hover {
        color: primary($theme, 700);
      }
    }
  }
}
