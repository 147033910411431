@use 'partials/colors';

@mixin theme($theme) {
  app-workspace-menu {
    .workspace {
      &:hover {
        background-color: fade-out(colors.bg($theme), 0.9);
      }

      app-loading-spinner {
        @extend %center-loading-spinner;
      }
    }
  }
}
