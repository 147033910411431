@use 'partials/colors';

@mixin theme($theme) {
  .mat-mdc-tab-header {
    --mat-tab-header-label-text-tracking: 0;
    --mdc-tab-indicator-active-indicator-height: 0.25rem;
  }

  .mdc-tab {
    &:not(.mat-mdc-tab-disabled) {
      &.mdc-tab--active {
        .mdc-tab__text-label {
          font-weight: bold;
        }
      }
    }
  }
}
