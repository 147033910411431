@use 'sass:map';
@use '@angular/material' as mat;
@use 'partials/colors';
@use 'partials/palette' as palette;
@use 'mixins';

@mixin common-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark-theme: map.get($color-config, 'is-dark');

  // This selects the <body> because of where the mixin is used
  & {
    color: colors.if($is-dark-theme, palette.$off-white, rgba(#232323, 0.7));
  }

  .color-75 {
    color: if($is-dark-theme, rgba(white, 0.75), rgba(black, 0.75));
  }

  .color-50 {
    color: if($is-dark-theme, rgba(white, 0.5), rgba(black, 0.5));
  }

  .color-25 {
    color: if($is-dark-theme, rgba(white, 0.25), rgba(black, 0.25));
  }

  .bg-25 {
    background-color: if($is-dark-theme, rgba(white, 0.25), rgba(black, 0.25));
  }

  .bg-30-hover:hover {
    background-color: if(
      $is-dark-theme,
      rgba(white, 0.3),
      rgba(black, 0.3)
    ) !important;
  }

  .bg-15 {
    background-color: if($is-dark-theme, rgba(white, 0.15), rgba(black, 0.15));
  }

  .bg-10 {
    background-color: if($is-dark-theme, rgba(white, 0.1), rgba(black, 0.1));
  }

  .bg-5 {
    background-color: if($is-dark-theme, rgba(white, 0.05), rgba(black, 0.05));
  }

  .theme {
    &-color {
      &-50 {
        color: rgba(colors.text-color($theme), 0.5) !important;
      }

      &-75 {
        color: rgba(colors.text-color($theme), 0.75) !important;
      }

      &-yellow {
        color: mat.get-color-from-palette(palette.$error, 'dark') !important;
      }

      &-yellow-alt {
        color: mat.get-color-from-palette(palette.$error, 'default') !important;
      }
    }

    &-bg {
      &-darkest {
        @include colors.bg-darkest($theme);
      }

      &-2 {
        background-color: if(
          $is-dark-theme,
          rgba(white, 0.015),
          rgba(black, 0.015)
        );
      }

      &-50 {
        background-color: if(
          $is-dark-theme,
          rgba(white, 0.5),
          rgba(black, 0.5)
        );
      }

      &-highlight {
        background: mat.get-color-from-palette(
          palette.$highlight-blue-palette,
          500
        );
        color: mat.get-contrast-color-from-palette(
          palette.$highlight-blue-palette,
          500
        );
      }

      &-success {
        color: colors.if($is-dark-theme, palette.$dark, white);
      }

      &-warn {
        color: colors.if($is-dark-theme, palette.$dark, white);
      }
    }
  }

  .success {
    &-bg {
      background-color: transparentize(
        darken(map.get(palette.$finishedSuccess, 'default'), 3%),
        0.9
      );
    }

    &-color {
      color: map.get(palette.$finishedSuccess, 'highlight');
    }
  }

  .error {
    &-bg {
      background-color: transparentize(
        darken(map.get(palette.$finishedError, 'default'), 3%),
        0.9
      );

      &-darker {
        background-color: transparentize(
          darken(map.get(palette.$finishedError, 'default'), 25%),
          0.9
        );
      }
    }

    &-color {
      color: map.get(palette.$finishedError, 'highlight');
    }
  }
}
