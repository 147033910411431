@use 'partials/colors';

@mixin theme($theme) {
  app-workspace-dropdown {
    background: colors.bg-app-bar($theme);
    color: colors.text-color($theme);

    .dropdown-header {
      background: colors.bg-status-bar($theme);
    }

    .dropdown-body-info {
      h4 {
        color: colors.text-alt($theme);
      }

      fa-icon {
        color: colors.primary($theme);
      }
    }

    .recently-viewed {
      background-color: colors.bg-alt($theme);
      border-top-color: colors.divider($theme);
    }
  }
}
