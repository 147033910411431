@mixin transitionFast($properties: all, $important: false) {
  $transition-properties: ();

  @each $property in $properties {
    $transition-properties: append(
      $transition-properties,
      $property 300ms ease-in-out,
      comma
    );
  }

  @if $important {
    transition: $transition-properties !important;
  } @else {
    transition: $transition-properties;
  }
}
