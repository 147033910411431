@use 'sass:map';
@use 'partials/colors';
@use 'partials/palette';

@mixin theme($theme) {
  .mat-mdc-snack-bar-container {
    border-radius: 0.5rem;
    margin-top: 3rem;
    --mdc-snackbar-container-color: #{colors.bg($theme)};
    --mdc-snackbar-supporting-text-color: #{colors.text-opacity($theme)};

    .snack-bar-icon {
      display: none;
    }

    &.snack-bar-error .mdc-snackbar__surface {
      $color: map.get(palette.$finishedError, highlight);
      border-left: 5px solid $color;

      .snack-bar-icon.error {
        color: $color !important;
        display: initial;
      }
    }

    &.snack-bar-warning .mdc-snackbar__surface {
      $color: map.get(palette.$error, default);
      border-left: 5px solid $color;

      .snack-bar-icon.warning {
        color: $color !important;
        display: initial;
      }
    }

    &.snack-bar-success .mdc-snackbar__surface {
      $color: map.get(palette.$finishedSuccess, highlight);
      border-left: 5px solid $color;

      .snack-bar-icon.success {
        color: $color !important;
        display: initial;
      }
    }
  }
}
