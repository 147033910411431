@use 'partials/colors';

@mixin theme($theme) {
  app-api-export {
    .mat-mdc-card-content {
      @include colors.text-secondary($theme);
    }

    .mdc-list-item {
      @include colors.text-secondary($theme);

      &--selected {
        background-color: colors.bg-focused($theme);
      }
    }
  }
}
