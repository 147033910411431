@use 'partials/variables' as var;

@mixin theme($theme) {
  .mat-toolbar-multiple-rows {
    min-height: #{var.$panel-height};
  }

  .mat-toolbar-row span {
    font-size: 13px;
  }

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: #{var.$panel-height};
  }
}
