body {
  .v2-style-wrapper {
    .mat-mdc-checkbox {
      .mdc-checkbox
        .mdc-checkbox__native-control:enabled:not(:checked):not(
          :indeterminate
        ):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        background-color: white;
        border: 0;
        border-radius: 0;
      }
    }
  }
}
