@use 'sass:map';
@use '@angular/material' as mat;
@use 'partials/variables' as var;
@use 'partials/colors';

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primaryPalette: mat.get-color-from-palette($color-config, 'primary');
  $backgroundPalette: mat.get-color-from-palette($color-config, 'background');

  [mat-stroked-button],
  [mat-button],
  [mat-icon-button],
  [mat-flat-button] {
    &.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 2.5rem;

      font-family: var.$base-font-family;
      font-size: var.$font-size-sm;
      font-weight: 500;
      letter-spacing: normal;
      white-space: nowrap;
    }

    .mat-mdc-button-touch-target {
      height: inherit;
      width: inherit;
    }

    &.btn-no-min-width {
      min-width: auto !important;
    }
  }

  [mat-icon-button] {
    &.mat-mdc-button-base {
      padding: 0;

      &:has(fa-icon[size='sm']) {
        height: var.$icon-size-sm;
        width: var.$icon-size-sm;
      }
    }
  }

  .mat-mdc-icon-button {
    fa-icon {
      svg,
      img {
        --mdc-icon-button-icon-size: 1em;
        vertical-align: -0.15em;
      }
    }
  }

  .mat-mdc-dialog-title,
  .small-button-wrapper,
  .small-panel-area-button,
  app-collapsible-panel-header {
    .mat-mdc-button,
    .mat-mdc-icon-button {
      &.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: #{var.$panel-height-sm};
      }
    }
  }

  .small-button-icon {
    &.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: #{var.$panel-height-sm};
    }
  }

  .mat-mdc-dialog-title .mat-mdc-icon-button {
    align-self: center;
  }

  [mat-stroked-button],
  [mat-button] {
    .mdc-button__label:has(span) fa-icon {
      margin-right: 0.5rem;
      vertical-align: middle;
    }

    &:not(:disabled) fa-icon {
      color: map.get($primaryPalette, 500);
    }
  }

  [mat-icon-button]:not(:disabled):hover {
    background-color: rgba(map.get($primaryPalette, 500), 0.12);

    fa-icon {
      color: map.get($primaryPalette, 500);
    }
  }

  [mat-icon-button].icon-button-outline {
    border: 1px solid map.get($backgroundPalette, disabled-button);
  }
}
