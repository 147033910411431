@use 'partials/variables' as var;

app-privacy-regnosys,
app-terms-regnosys,
app-terms-isda,
app-release-notes-viewer-standalone {
  display: block;
  height: calc(100% - 200px);
  padding: 100px;

  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 24px;
    margin: 0 auto;
    border-radius: var.$border-radius-sm;
    max-height: 900px;
    height: 100%;
    max-width: 750px;
  }

  .scroll-container {
    overflow: auto;
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    padding: 15px;
    margin-bottom: 40px;
  }

  app-logo {
    height: 43px;
    width: 230px;
    margin: 0 auto 40px;
  }

  // style hack to avoid having to change the markup
  dt {
    margin-bottom: -20px;
  }

  h2 dt {
    margin-bottom: -32px;
  }

  .fix-overlap {
    padding-bottom: 20px;
  }
}
