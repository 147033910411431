$success: #018301;
$success_alt: #32ca32;
$pending: #f5ac26;
$warning: #d83737;

$finishedSuccess: (
  'default': #8fd689,
  'lighter': #f0f6f1,
  'dark': #2c382e,
  'highlight': #20ac13,
);

$finishedError: (
  'default': #eb9b9b,
  'lighter': #fdf5f5,
  'dark': #442424,
  'highlight': #d83737,
);

$error: (
  'default': #ffd600,
  'lighter': #fbf7e1,
  'dark': #524b26,
  'highlight': #ffd600,
);

$dark: #414141;
$light: white;
$off-white: #cccccc; // used for text on dark backgrounds

$dark-primary-text: $dark;
$light-primary-text: $light;

$dark-pink-palette: (
  100: #e170ad,
  // Lighter
  500: #c4297d,
  // Default
  700: #6e1746,
  // Darker
  contrast: (
      100: $dark-primary-text,
      500: $light-primary-text,
      700: $light-primary-text,
    ),
);

$light-pink-palette: (
  100: #f49fc6,
  // Lighter
  500: #e94591,
  // Default
  700: #b2155e,
  // Darker
  contrast: (
      100: $dark-primary-text,
      500: $light-primary-text,
      700: $light-primary-text,
    ),
);

$dark-green-palette: (
  100: #a6c9ac,
  // Lighter
  500: #65a270,
  // Default
  700: #3d6645,
  // Darker
  contrast: (
      100: $dark-primary-text,
      500: $light-primary-text,
      700: $light-primary-text,
    ),
);

$light-green-palette: (
  100: #d9eddf,
  // Lighter
  500: #96cca6,
  // Default
  700: #52a86b,
  // Darker
  contrast: (
      100: $dark-primary-text,
      500: $dark-primary-text,
      700: $light-primary-text,
    ),
);

$lime-palette: (
  100: #e2e99b,
  // Lighter
  500: #c9d646,
  // Default
  700: #8c9621,
  // Darker
  900: #585b38,
  // Darkest
  contrast: (
      100: $dark-primary-text,
      500: $dark-primary-text,
      700: $light-primary-text,
    ),
);

$light-blue-palette: (
  100: #ffffff,
  // Lighter
  500: #dceff2,
  // Default
  700: #93ced7,
  // Darker
  contrast: (
      100: $dark-primary-text,
      500: $dark-primary-text,
      700: $dark-primary-text,
    ),
);

$dark-blue-palette: (
  100: #8da1b5,
  // Lighter
  500: #576f86,
  // Default
  700: #2e3b47,
  // Darker
  contrast: (
      100: $light-primary-text,
      500: $light-primary-text,
      700: $light-primary-text,
    ),
);

$highlight-blue-palette: (
  100: #85dcef,
  // Lighter
  500: #29c2e3,
  // Default
  700: #137e95,
  // Darker
  contrast: (
      100: $dark-primary-text,
      500: $light-primary-text,
      700: $light-primary-text,
    ),
);

$dark-grey-palette: (
  100: #737373,
  // Lighter
  500: #414141,
  // Default
  700: #0d0d0d,
  // Darker
  contrast: (
      100: $light-primary-text,
      500: $light-primary-text,
      700: $light-primary-text,
    ),
);

$red-palette: (
  100: #e1707f,
  // Lighter
  500: #c4293e,
  // Default
  700: #6e1723,
  // Darker
  contrast: (
      100: $dark-primary-text,
      500: $light-primary-text,
      700: $light-primary-text,
    ),
);
