@use 'partials/colors';
@use 'mixins';
@use 'partials/palette';

@mixin theme($theme) {
  app-code-view {
    background-color: colors.bg($theme);
    color: colors.text-secondary-color($theme);

    header {
      border-bottom: 1px solid colors.divider($theme);
    }

    .mat-tree {
      .mat-tree-node {
        background-color: colors.bg($theme);
        @include mixins.transitionFast(all);

        > div {
          border-bottom: 1px solid lighten(colors.divider($theme), 50);
        }

        .node-name,
        .node-value {
          &.conditional {
            color: palette.$success_alt;
          }

          &.mapped {
            color: palette.$success;
          }

          &.excluded {
            color: palette.$pending;
          }

          &.unmapped,
          &.invalid,
          &.error {
            color: palette.$warning;
          }
        }

        &.group-node {
          background-color: darken(colors.bg($theme), 2);
        }

        &.selected {
          background-color: darken(colors.bg($theme), 10);

          div:first-child {
            border-left: 4px solid colors.primary($theme);
          }
        }
      }
    }
  }
}
