.ingestion-upload-container {
  file-drop .drop-zone {
    height: 100%;
    width: 100%;
    padding: 10px;
    border: 2px dotted;
    border-radius: 10px;

    i {
      font-size: 20px;
      padding: 5px;
    }

    span {
      font-size: 13px;
      padding: 5px;
    }
  }
}
