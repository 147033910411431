@mixin theme($theme) {
  .mat-mdc-select {
    &-arrow-wrapper {
      padding-left: 0.5rem;
    }
  }

  .mat-mdc-form-field {
    &.auto-width {
      .mat-mdc-form-field-infix {
        width: auto !important;
        min-width: 8rem; // Set min-width to avoid truncation long placeholder text
      }
    }
  }
}
