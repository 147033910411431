@use 'sass:color';
@use 'partials/colors';
@use 'partials/variables' as var;
@use 'mixins';

@mixin typography-theme($theme) {
  code {
    font-variant-ligatures: none;
    font-size: 0.75rem;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: normal;
    tab-size: 4;
    padding: 2px 3px 1px;
    border: none;
    border-radius: var.$border-radius-sm;
    background-color: var(--rosetta-border-color-light-active);
    color: var(--rosetta-text-color);
  }

  .font-text-link,
  .font-text-link-underline {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      outline: 1px dashed colors.primary($theme);
    }
  }

  .font-text-link-underline {
    text-decoration: underline;
    cursor: pointer;
    @include mixins.transitionFast(color);

    &:hover {
      color: colors.accent($theme);
    }
  }

  .font-mark {
    background-color: color.adjust(colors.accent($theme), $alpha: -0.3);
    color: color.adjust(colors.accent($theme), $alpha: -0.75);
  }
}
