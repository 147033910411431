@use '@angular/material' as mat;
@use 'theming/component-themes' as themes;
@use 'theming/material-component-theming' as component-themes;
@use 'partials/palette';

$typography-config: mat.define-typography-config(
  $font-family: 'Inter, Arial, sans-serif',
  $body-1: mat.define-typography-level(14px, 20px, 400),
);

@include mat.all-component-typographies($typography-config);
@include mat.core();

$theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(palette.$dark-pink-palette),
      accent: mat.define-palette(palette.$light-pink-palette),
      warn: mat.define-palette(palette.$red-palette),
    ),
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette(palette.$lime-palette),
      accent: mat.define-palette(palette.$lime-palette, 500),
      warn: mat.define-palette(palette.$red-palette, 100),
    ),
  )
);

// DEFAULT THEME
// Light theme class is here to increase specificity
body,
.light-theme {
  @include component-themes.material-component-theming($theme);
  @include themes.rosetta-theme($theme);
}

.dark-theme {
  @include component-themes.material-component-color($dark-theme);
  @include themes.rosetta-theme($dark-theme);
}

@import './theming/component-themes-v2';
