@use 'sass:map';
@use 'partials/palette';
@use 'mixins';

/*
  Intercom override styling

  This styling is required to integrate
  the intercom widget into our UI.

  Intercom Widget Elements:
  - intercom-launcher
  - intercom-launcher-frame
  - intercom-launcher-badge-frame
  - intercom-notifications-frame
  - intercom-borderless-frame
  - intercom-messenger-frame
 */

.intercom-launcher,
.intercom-launcher-frame {
  animation: none;
  @include mixins.transitionFast(all, true);
}

.integrate-intercom {
  .intercom-launcher,
  .intercom-launcher-frame,
  .intercom-messenger-frame + div {
    box-shadow: none !important;
    right: 6px !important;
    bottom: 6px !important;
  }

  .intercom-messenger-frame {
    bottom: 60px !important;
    transition:
      width 1s ease 0s,
      height 1s ease 0s,
      bottom 300ms ease !important;
  }

  .intercom-launcher-badge-frame {
    bottom: 38px !important;
    right: 34px !important;
  }

  .intercom-borderless-frame {
    bottom: 60px !important;
  }

  iframe[name='intercom-notifications-frame'] {
    bottom: 60px !important;
    right: 14px !important;
  }
}
