.button-reset {
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;

  & > * {
    vertical-align: middle;
  }
}

a.inline-link {
  color: inherit;
}
