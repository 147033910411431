@use '@ag-grid-community/styles' as ag;
@use '@angular/material' as mat;
@use 'partials/palette';

$header-height: 56px;

@include ag.grid-styles(
  (
    themes: (
      alpine: (
        odd-row-background-color: darken($color: white, $amount: 2),
        row-hover-color: darken($color: white, $amount: 6),
        header-background-color: darken($color: white, $amount: 0.75),
        alpine-active-color: mat.get-color-from-palette(
            palette.$light-pink-palette,
            700
          ),
        input-focus-border-color: mat.get-color-from-palette(
            palette.$light-pink-palette,
            700
          ),
        control-panel-background-color: darken($color: white, $amount: 0.75),
      ),
      alpine-dark: (
        background-color: #424242,
        odd-row-background-color: lighten($color: #424242, $amount: 2),
        header-background-color: lighten($color: #424242, $amount: 2),
        row-hover-color: lighten($color: #2f2f2f, $amount: 4),
        alpine-active-color: mat.get-color-from-palette(
            palette.$lime-palette,
            500
          ),
        input-focus-border-color: mat.get-color-from-palette(
            palette.$lime-palette,
            500
          ),
        control-panel-background-color: lighten($color: #424242, $amount: 2),
      ),
    ),
    font-family: (
      Inter,
      'Helvetica Neue',
      sans-serif,
    ),
    grid-size: 9.3px,
    row-height: 64px,
    header-height: $header-height,
    cell-horizontal-padding: 24px,
    row-border-color: transparent,
    borders-critical: 0 solid,
    input-focus-box-shadow: none,
  )
);

.cell-multi-line {
  line-height: 20px !important;
  padding-top: 5px;
  /* space top */
  padding-bottom: 5px;
  /* space bottom */
}

.cell-label {
  position: relative;
  border-radius: 10px;
  width: 100px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
}

.ag-overlay {
  height: calc(100% - $header-height);
  top: $header-height;
  overflow: hidden;
  border: 1px solid rgba(0 0 0 / 4%);
  pointer-events: auto !important;
}

.ag-pinned-right-cols-container,
.ag-center-cols-container {
  // This is here to remove the extra margin added for the fake scrollbar
  // as it causes the columns not to align to their headers
  margin-right: 0 !important;
}
