@use 'partials/colors';
@use '@angular/material' as mat;
@use 'partials/palette';

@mixin theme($theme) {
  .v2-style-wrapper {
    .mat-mdc-form-field {
      --mdc-filled-text-field-container-color: #{colors.bg($theme)};
    }
  }

  .cdk-overlay-pane:has(.mat-mdc-select-panel) {
    width: auto !important;
  }

  .mat-mdc-form-field {
    --mdc-outlined-text-field-outline-color: #{colors.border-color-light(
        $theme
      )};
    --mdc-filled-text-field-container-color: transparent;
    @include mat.form-field-density(-1);

    &:hover,
    &.mat-focused {
      .mat-mdc-form-field-focus-overlay {
        opacity: 0.03 !important;
      }
    }
  }
}
