@use 'sass:map';
@use '@angular/material' as mat;
@use 'partials/palette';
@use 'partials/colors';

@mixin theme($theme) {
  $border-style: 3px solid rgba(0, 0, 0, 0.03);

  .rosetta-table-empty {
    --ag-background-color: var(--ag-header-background-color);
  }

  .ag-header {
    border-bottom: 3px solid rgba(0, 0, 0, 0.06);
  }

  .ag-pinned-left-header,
  .ag-pinned-right-header {
    --ag-header-column-resize-handle-height: 100%;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    border-right: $border-style;
  }

  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
      .ag-cell-range-single-cell
    ) {
    border-left: $border-style;
  }

  .ag-pinned-left-header,
  .ag-pinned-right-header,
  .ag-pinned-left-cols-container,
  .ag-pinned-right-cols-container {
    .ag-row,
    .ag-header-row {
      background-color: rgb(0 0 0 / 2%);
    }
  }

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    .ag-header-row {
      border-right: $border-style;
    }
  }

  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    .ag-header-row {
      border-left: $border-style;
    }
  }

  .ros {
    &-no-horizontal-padding {
      --ag-cell-horizontal-padding: 0;
    }

    &-row-disabled > .ag-cell {
      opacity: 0.5;
      cursor: default;
      pointer-events: 'none';
    }

    &-row-error > .ag-cell {
      cursor: initial;
      opacity: 0.5;
      background-color: transparentize(
        darken(map.get(palette.$finishedError, 'default'), 2%),
        0.7
      );
    }

    // TODO: remove "-cell-error" class once V2 transform as been fully implemented
    &-cell-error,
    &-cell-diff {
      background-color: transparentize(
        darken(map.get(palette.$finishedError, 'default'), 2%),
        0.7
      );
    }

    &-cell-warning {
      background-color: transparentize(
        darken(map.get(palette.$error, 'default'), 2%),
        0.7
      );
    }

    // TODO: remove "-cell-override" class once V2 transform as been fully implemented
    &-cell-override,
    &-cell-updated {
      background-color: transparentize(
        darken(map.get(palette.$highlight-blue-palette, 500), 2%),
        0.7
      );
    }
  }

  .ag-header-cell-filtered {
    background-color: colors.primary($theme) !important;

    [ref='eFilter'] {
      display: none;
    }

    [ref='eMenu'] {
      opacity: 1;
    }

    span {
      color: colors.primary-contrast($theme) !important;
    }
  }
}
