@use 'partials/variables' as var;

%dropdown-icon {
  padding: 0 0.25rem;
  font-size: 1rem;
}

%menu-icon {
  margin-right: 0.75rem;
  opacity: 0.7;
}

fa-icon {
  &.fa-icon-button {
    width: var.$icon-size;
    height: var.$icon-size;
    line-height: var.$icon-size;
    text-align: center;
  }
}
