@use 'partials/colors';

@mixin theme($theme) {
  app-rosetta-graphical-navigator {
    .warning-banner {
      color: colors.text-color($theme);
      background-color: colors.bg-status-bar($theme);
    }

    .warning-banner,
    .warning-overlay {
      fa-icon {
        color: colors.warn($theme);
      }
    }

    .mat-toolbar .selected fa-icon {
      color: colors.primary($theme);
    }

    .container {
      background-color: colors.text-color($theme);
    }

    app-loading-spinner {
      @extend %center-loading-spinner;
    }
  }
}
