@use 'partials/colors';

@mixin theme($theme) {
  app-user-profile-form {
    .form-container {
      background: colors.bg-alt($theme);
    }

    // These styles are here due to a bug with dynamic component style scoping
    button app-loading-spinner {
      margin-right: 8px;

      // Ensure the spinner is the in the same position as the icon
      margin-left: -5.75px;
    }
  }
}
