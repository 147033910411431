@use 'partials/variables' as var;

[hidden] {
  display: none !important;
}

.max-width-container {
  margin: 0 auto;
  max-width: var.$max-width;
  padding: 0 24px;
}

.spacer {
  flex: 1 0 12px;
}

.spacer-no-gap {
  flex: 1;
}

.text-ellipsis,
.truncated-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
